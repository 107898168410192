import { useSettings } from '../context/SettingsContext';

/**
 * SettingsPanel component for customizing application settings
 */
export function SettingsPanel({ onClose }) {
  const { settings, updateSetting, resetSettings } = useSettings();

  // Handle input changes
  const handleChange = (key, value) => {
    updateSetting(key, value);
  };

  // Handle checkbox changes
  const handleCheckboxChange = (key) => {
    updateSetting(key, !settings[key]);
  };

  return (
    <div className="modal">
      <div className="modal-content settings-panel">
        <div className="settings-header">
          <h2>Settings</h2>
        </div>

        <div className="settings-body">
          <div className="settings-section">
            <h3>
              <i className="fas fa-font settings-icon"></i>
              Editor Appearance
            </h3>
            
            <div className="settings-item">
              <label htmlFor="fontFamily">Font Family</label>
              <select
                id="fontFamily"
                className="settings-select"
                value={settings.fontFamily}
                onChange={(e) => handleChange('fontFamily', e.target.value)}
              >
                <option value="Segoe UI, Tahoma, Geneva, Verdana, sans-serif">Segoe UI</option>
                <option value="Monaco, Consolas, 'Courier New', monospace">Monaco</option>
                <option value="'Courier New', Courier, monospace">Courier New</option>
                <option value="Arial, sans-serif">Arial</option>
                <option value="Georgia, serif">Georgia</option>
              </select>
            </div>

            <div className="settings-item">
              <label htmlFor="fontSize">Font Size</label>
              <select
                id="fontSize"
                className="settings-select"
                value={settings.fontSize}
                onChange={(e) => handleChange('fontSize', e.target.value)}
              >
                <option value="12px">12px</option>
                <option value="14px">14px</option>
                <option value="16px">16px</option>
                <option value="18px">18px</option>
                <option value="20px">20px</option>
              </select>
            </div>

            <div className="settings-item">
              <label htmlFor="lineHeight">Line Height</label>
              <select
                id="lineHeight"
                className="settings-select"
                value={settings.lineHeight}
                onChange={(e) => handleChange('lineHeight', e.target.value)}
              >
                <option value="1.2">Compact (1.2)</option>
                <option value="1.6">Normal (1.6)</option>
                <option value="2.0">Spacious (2.0)</option>
              </select>
            </div>

            <div className="settings-item">
              <label htmlFor="editorPadding">Editor Padding</label>
              <select
                id="editorPadding"
                className="settings-select"
                value={settings.editorPadding}
                onChange={(e) => handleChange('editorPadding', e.target.value)}
              >
                <option value="5px">Small (5px)</option>
                <option value="10px">Medium (10px)</option>
                <option value="20px">Large (20px)</option>
              </select>
            </div>
          </div>

          <div className="settings-divider"></div>

          <div className="settings-section">
            <h3>
              <i className="fas fa-sliders-h settings-icon"></i>
              Editor Behavior
            </h3>
            
            <div className="settings-checkbox-item">
              <div className="checkbox-container">
                <input
                  id="autoIndent"
                  type="checkbox"
                  className="settings-checkbox"
                  checked={settings.autoIndent}
                  onChange={() => handleCheckboxChange('autoIndent')}
                />
                <label htmlFor="autoIndent" className="checkbox-label">Auto Indent</label>
              </div>
              <div className="setting-description">Automatically indent code blocks and lists</div>
            </div>
            
            <div className="settings-checkbox-item">
              <div className="checkbox-container">
                <input
                  id="wordWrap"
                  type="checkbox"
                  className="settings-checkbox"
                  checked={settings.wordWrap}
                  onChange={() => handleCheckboxChange('wordWrap')}
                />
                <label htmlFor="wordWrap" className="checkbox-label">Word Wrap</label>
              </div>
              <div className="setting-description">Wrap text to fit the editor width</div>
            </div>
            
            <div className="settings-checkbox-item">
              <div className="checkbox-container">
                <input
                  id="showPreview"
                  type="checkbox"
                  className="settings-checkbox"
                  checked={settings.showPreview}
                  onChange={() => handleCheckboxChange('showPreview')}
                />
                <label htmlFor="showPreview" className="checkbox-label">Show Preview</label>
              </div>
              <div className="setting-description">Display markdown preview alongside the editor</div>
            </div>
          </div>

          <div className="settings-divider"></div>

          <div className="settings-section">
            <h3>
              <i className="fas fa-cog settings-icon"></i>
              Other Settings
            </h3>
            
            <div className="settings-checkbox-item">
              <div className="checkbox-container">
                <input
                  id="markdownGuide"
                  type="checkbox"
                  className="settings-checkbox"
                  checked={settings.markdownGuide}
                  onChange={() => handleCheckboxChange('markdownGuide')}
                />
                <label htmlFor="markdownGuide" className="checkbox-label">Show Markdown Guide</label>
              </div>
              <div className="setting-description">Display a reference guide for markdown syntax</div>
            </div>
          </div>
        </div>

        <div className="settings-footer">
          <button 
            className="settings-button reset-button"
            onClick={resetSettings}
          >
            <i className="fas fa-undo-alt"></i> Reset to Defaults
          </button>
          <button 
            className="settings-button close-button"
            onClick={onClose}
          >
            <i className="fas fa-check"></i> Close
          </button>
        </div>
      </div>
    </div>
  );
}
