import { useState } from 'preact/hooks';
import { Modal } from './Modal';
import { useTabs } from '../context/TabsContext';

/**
 * TableBuilder component for creating Markdown tables
 */
export function TableBuilder({ isOpen, onClose }) {
  const { getActiveTab, updateTabContent } = useTabs();
  const [rows, setRows] = useState(3);
  const [columns, setColumns] = useState(3);
  const [headers, setHeaders] = useState(['Header 1', 'Header 2', 'Header 3']);
  const [alignments, setAlignments] = useState(Array(3).fill('left'));

  // Update headers when number of columns changes
  const handleColumnsChange = (e) => {
    const newColumnCount = parseInt(e.target.value);
    setColumns(newColumnCount);
    
    // Adjust headers array
    if (newColumnCount > headers.length) {
      // Add new headers
      const newHeaders = [...headers];
      for (let i = headers.length; i < newColumnCount; i++) {
        newHeaders.push(`Header ${i + 1}`);
      }
      setHeaders(newHeaders);
      
      // Add new alignments
      const newAlignments = [...alignments];
      for (let i = alignments.length; i < newColumnCount; i++) {
        newAlignments.push('left');
      }
      setAlignments(newAlignments);
    } else if (newColumnCount < headers.length) {
      // Remove excess headers
      setHeaders(headers.slice(0, newColumnCount));
      setAlignments(alignments.slice(0, newColumnCount));
    }
  };

  // Update a specific header
  const handleHeaderChange = (index, value) => {
    const newHeaders = [...headers];
    newHeaders[index] = value;
    setHeaders(newHeaders);
  };

  // Update a specific column alignment
  const handleAlignmentChange = (index, value) => {
    const newAlignments = [...alignments];
    newAlignments[index] = value;
    setAlignments(newAlignments);
  };

  // Generate the Markdown table
  const generateTable = () => {
    const activeTab = getActiveTab();
    if (!activeTab) return;

    // Generate header row
    let table = '| ' + headers.join(' | ') + ' |\n';
    
    // Generate alignment row
    table += '| ';
    for (let i = 0; i < columns; i++) {
      switch (alignments[i]) {
        case 'left':
          table += ':--- | ';
          break;
        case 'center':
          table += ':---: | ';
          break;
        case 'right':
          table += '---: | ';
          break;
        default:
          table += '--- | ';
      }
    }
    table = table.slice(0, -2) + '\n';
    
    // Generate data rows
    for (let i = 0; i < rows; i++) {
      table += '| ';
      for (let j = 0; j < columns; j++) {
        table += 'Cell ' + (i + 1) + '-' + (j + 1) + ' | ';
      }
      table = table.slice(0, -2) + '\n';
    }
    
    // Insert the table at cursor position
    const textarea = document.querySelector(`textarea[data-tab-id="${activeTab.id}"]`);
    if (!textarea) {
      // If we can't find the textarea, just append to the end
      updateTabContent(activeTab.id, activeTab.content + '\n\n' + table);
    } else {
      const start = textarea.selectionStart;
      const content = activeTab.content;
      const newContent = content.substring(0, start) + '\n' + table + content.substring(start);
      updateTabContent(activeTab.id, newContent);
      
      // Set cursor after the table
      setTimeout(() => {
        textarea.focus();
        const newCursorPos = start + table.length + 1;
        textarea.setSelectionRange(newCursorPos, newCursorPos);
      }, 0);
    }
    
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Insert Table"
      className="table-builder-modal"
    >
      <div className="table-builder-form">
        <div className="form-group">
          <label htmlFor="tableRows">Rows:</label>
          <input
            id="tableRows"
            type="number"
            min="1"
            max="10"
            value={rows}
            onChange={(e) => setRows(parseInt(e.target.value))}
            className="form-input"
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="tableColumns">Columns:</label>
          <input
            id="tableColumns"
            type="number"
            min="1"
            max="10"
            value={columns}
            onChange={handleColumnsChange}
            className="form-input"
          />
        </div>
        
        <div className="form-group table-headers">
          <label>Headers:</label>
          <div className="headers-container">
            {headers.map((header, index) => (
              <div key={index} className="header-row">
                <input
                  type="text"
                  value={header}
                  onChange={(e) => handleHeaderChange(index, e.target.value)}
                  className="form-input"
                  placeholder={`Header ${index + 1}`}
                />
                <select
                  value={alignments[index]}
                  onChange={(e) => handleAlignmentChange(index, e.target.value)}
                  className="form-select"
                >
                  <option value="left">Left</option>
                  <option value="center">Center</option>
                  <option value="right">Right</option>
                </select>
              </div>
            ))}
          </div>
        </div>
        
        <div className="form-preview">
          <div className="preview-label">Preview:</div>
          <div className="table-preview">
            <table>
              <thead>
                <tr>
                  {headers.map((header, index) => (
                    <th key={index} style={{ textAlign: alignments[index] }}>
                      {header || `Header ${index + 1}`}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Array(rows).fill(0).map((_, rowIndex) => (
                  <tr key={rowIndex}>
                    {Array(columns).fill(0).map((_, colIndex) => (
                      <td key={colIndex} style={{ textAlign: alignments[colIndex] }}>
                        Cell {rowIndex + 1}-{colIndex + 1}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        
        <div className="form-actions">
          <button className="button-modern" onClick={onClose}>
            Cancel
          </button>
          <button className="button-modern primary" onClick={generateTable}>
            Insert Table
          </button>
        </div>
      </div>
    </Modal>
  );
}
