import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

/**
 * PrivacyPanel component to display the privacy policy from Markdown.
 */
export function PrivacyPanel({ onClose }) {
  const [privacyContent, setPrivacyContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch Markdown content from the file in the public directory
    fetch('/docs/privacy.md')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then(text => {
        // Parse Markdown and sanitize HTML
        const rawHtml = marked.parse(text);
        const cleanHtml = DOMPurify.sanitize(rawHtml);
        setPrivacyContent(cleanHtml);
        setIsLoading(false);
      })
      .catch(err => {
        console.error("Error fetching or parsing privacy.md:", err);
        setError('Failed to load privacy policy. Please try again later.');
        setIsLoading(false);
      });
  }, []);

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content privacy-panel" onClick={(e) => e.stopPropagation()}>
        <div className="settings-header"> {/* Reuse style */}
          <h2>Privacy Policy</h2>
           <button onClick={onClose} className="close-button" aria-label="Close Privacy Policy">×</button>
        </div>

        <div className="settings-body privacy-body"> {/* Reuse style */}
          {isLoading && <p>Loading privacy policy...</p>}
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {!isLoading && !error && (
            <div dangerouslySetInnerHTML={{ __html: privacyContent }}></div>
          )}
        </div>

        <div className="settings-footer"> {/* Reuse style */}
           <button onClick={onClose} className="settings-button">Close</button>
        </div>
      </div>
    </div>
  );
}
