import { render } from 'preact';
import { App } from './components/App';
import { SettingsProvider } from './context/SettingsContext';
import { ThemeProvider } from './context/ThemeContext';
import { TabsProvider } from './context/TabsContext';
import './styles/global.css';

// Render the application to the DOM
render(
  <SettingsProvider>
    <ThemeProvider>
      <TabsProvider>
        <App />
      </TabsProvider>
    </ThemeProvider>
  </SettingsProvider>,
  document.getElementById('app')
);
