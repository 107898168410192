import { useState } from 'preact/hooks';
import { Modal } from './Modal';
import { useTabs } from '../context/TabsContext';

/**
 * ImageInserter component for adding images to Markdown content
 */
export function ImageInserter({ isOpen, onClose }) {
  const { getActiveTab, updateTabContent } = useTabs();
  const [imageUrl, setImageUrl] = useState('');
  const [altText, setAltText] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [alignment, setAlignment] = useState('none');

  // Reset form when modal opens
  useState(() => {
    if (isOpen) {
      setImageUrl('');
      setAltText('');
      setWidth('');
      setHeight('');
      setAlignment('none');
    }
  }, [isOpen]);

  // Insert the image into the editor
  const insertImage = () => {
    const activeTab = getActiveTab();
    if (!activeTab) return;

    // Basic Markdown image syntax
    let markdown = `![${altText}](${imageUrl})`;
    
    // Add HTML for additional features if needed
    if (width || height || alignment !== 'none') {
      let html = '<img src="' + imageUrl + '" alt="' + altText + '"';
      
      if (width) html += ` width="${width}"`;
      if (height) html += ` height="${height}"`;
      
      if (alignment === 'left') {
        html += ` style="float: left; margin-right: 10px;"`;
      } else if (alignment === 'right') {
        html += ` style="float: right; margin-left: 10px;"`;
      } else if (alignment === 'center') {
        html += ` style="display: block; margin: 0 auto;"`;
      }
      
      html += '>';
      markdown = html;
    }
    
    // Insert at cursor position or at the end
    const textarea = document.querySelector(`textarea[data-tab-id="${activeTab.id}"]`);
    if (!textarea) {
      // If we can't find the textarea, just append to the end
      updateTabContent(activeTab.id, activeTab.content + '\n\n' + markdown);
    } else {
      const start = textarea.selectionStart;
      const content = activeTab.content;
      const newContent = content.substring(0, start) + markdown + content.substring(start);
      updateTabContent(activeTab.id, newContent);
      
      // Set cursor after the image
      setTimeout(() => {
        textarea.focus();
        const newCursorPos = start + markdown.length;
        textarea.setSelectionRange(newCursorPos, newCursorPos);
      }, 0);
    }
    
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Insert Image"
      className="image-inserter-modal"
    >
      <div className="image-form">
        <div className="form-group">
          <label htmlFor="imageUrl">Image URL:</label>
          <input
            id="imageUrl"
            type="text"
            value={imageUrl}
            onChange={(e) => setImageUrl(e.target.value)}
            className="form-input"
            placeholder="https://example.com/image.jpg"
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="altText">Alt Text:</label>
          <input
            id="altText"
            type="text"
            value={altText}
            onChange={(e) => setAltText(e.target.value)}
            className="form-input"
            placeholder="Description of the image"
          />
        </div>
        
        <div className="form-row">
          <div className="form-group half">
            <label htmlFor="imageWidth">Width:</label>
            <input
              id="imageWidth"
              type="text"
              value={width}
              onChange={(e) => setWidth(e.target.value)}
              className="form-input"
              placeholder="e.g., 300 or 50%"
            />
          </div>
          
          <div className="form-group half">
            <label htmlFor="imageHeight">Height:</label>
            <input
              id="imageHeight"
              type="text"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
              className="form-input"
              placeholder="e.g., 200"
            />
          </div>
        </div>
        
        <div className="form-group">
          <label htmlFor="imageAlignment">Alignment:</label>
          <select
            id="imageAlignment"
            value={alignment}
            onChange={(e) => setAlignment(e.target.value)}
            className="form-select"
          >
            <option value="none">None</option>
            <option value="left">Left</option>
            <option value="center">Center</option>
            <option value="right">Right</option>
          </select>
        </div>
        
        {imageUrl && (
          <div className="form-preview">
            <div className="preview-label">Preview:</div>
            <div 
              className="image-preview"
              style={{
                textAlign: alignment === 'none' ? 'left' : alignment
              }}
            >
              <img
                src={imageUrl}
                alt={altText}
                style={{
                  width: width ? width : 'auto',
                  height: height ? height : 'auto',
                  maxWidth: '100%'
                }}
                onError={(e) => {
                  e.target.src = 'data:image/svg+xml;charset=utf-8,%3Csvg xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22 width%3D%22100%22 height%3D%22100%22%3E%3Crect fill%3D%22%23ddd%22 width%3D%22100%22 height%3D%22100%22%2F%3E%3Ctext fill%3D%22%23888%22 font-family%3D%22sans-serif%22 font-size%3D%2212%22 dy%3D%22.3em%22 text-anchor%3D%22middle%22 x%3D%2250%22 y%3D%2250%22%3EImage error%3C%2Ftext%3E%3C%2Fsvg%3E';
                }}
              />
            </div>
          </div>
        )}
        
        <div className="form-actions">
          <button className="button-modern" onClick={onClose}>
            Cancel
          </button>
          <button 
            className="button-modern primary" 
            onClick={insertImage}
            disabled={!imageUrl}
          >
            Insert Image
          </button>
        </div>
      </div>
    </Modal>
  );
}
