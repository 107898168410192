import { createContext } from 'preact';
import { useContext, useState, useEffect } from 'preact/hooks';

// Create the settings context
const SettingsContext = createContext();

// Default settings
const defaultSettings = {
  // Editor appearance
  fontFamily: "'Source Code Pro', monospace",
  fontSize: '16px',
  lineHeight: '1.6',
  editorPadding: '16px',
  
  // Editor behavior
  autoIndent: true,
  wordWrap: true,
  showPreview: false,
  
  // Preview settings
  previewFontFamily: "'Segoe UI', sans-serif",
  previewFontSize: '16px',
  
  // Export options
  defaultExportFormat: 'markdown',
  
  // UI settings
  tabIcons: true,
  readingTimeEnabled: true,
  quotesEnabled: true,
  theme: 'dark'
};

/**
 * Settings provider for application preferences
 */
export function SettingsProvider({ children }) {
  // State for settings
  const [settings, setSettings] = useState(defaultSettings);
  
  // Load settings from localStorage on initial mount
  useEffect(() => {
    try {
      const savedSettings = localStorage.getItem('noteSettings');
      if (savedSettings) {
        // Merge saved settings with defaults for any new settings
        setSettings(prev => ({
          ...defaultSettings,
          ...JSON.parse(savedSettings)
        }));
      }
    } catch (error) {
      console.error('Error loading settings:', error);
    }
  }, []);
  
  // Save settings to localStorage whenever they change
  useEffect(() => {
    localStorage.setItem('noteSettings', JSON.stringify(settings));
  }, [settings]);
  
  // Update a single setting
  const updateSetting = (key, value) => {
    setSettings(prev => ({
      ...prev,
      [key]: value
    }));
  };
  
  // Update multiple settings at once
  const updateSettings = (newSettings) => {
    setSettings(prev => ({
      ...prev,
      ...newSettings
    }));
  };
  
  // Reset settings to default
  const resetSettings = () => {
    setSettings(defaultSettings);
    localStorage.removeItem('noteSettings');
  };
  
  const value = {
    settings,
    updateSetting,
    updateSettings,
    resetSettings
  };
  
  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
}

/**
 * Hook to use the settings context
 */
export function useSettings() {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
}
