import { useSettings } from '../context/SettingsContext';
import { useEffect, useState, useRef } from 'preact/hooks';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

/**
 * Preview component that renders markdown content
 */
export function Preview({ content }) {
  const { settings } = useSettings();
  const [html, setHtml] = useState('');
  const [previewWidth, setPreviewWidth] = useState(400); // Default width
  const [isDragging, setIsDragging] = useState(false);
  const previewRef = useRef(null);
  const startXRef = useRef(0);
  const startWidthRef = useRef(0);
  
  // Parse markdown to HTML when content changes
  useEffect(() => {
    if (!content) {
      setHtml('');
      return;
    }
    
    try {
      // Configure marked options
      marked.setOptions({
        breaks: true,           // Add line breaks
        gfm: true,              // GitHub Flavored Markdown
        headerIds: true,        // Add IDs to headers
        mangle: false,          // Don't mangle header IDs
        smartLists: true,       // Use smarter list behavior
        smartypants: true,      // Use "smart" typographic punctuation
      });
      
      // Convert markdown to HTML and sanitize to prevent XSS
      const parsedHtml = DOMPurify.sanitize(marked.parse(content));
      setHtml(parsedHtml);
      
      // Handle checkboxes after rendering
      setTimeout(() => {
        setupCheckboxListeners();
      }, 0);
    } catch (error) {
      console.error('Error parsing markdown:', error);
      setHtml('<p>Error rendering markdown preview.</p>');
    }
  }, [content]);
  
  // Handle drag start
  const handleDragStart = (e) => {
    e.preventDefault();
    setIsDragging(true);
    startXRef.current = e.clientX;
    startWidthRef.current = previewRef.current?.offsetWidth || previewWidth;
    
    // Add event listeners for dragging
    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', handleDragEnd);
  };
  
  // Handle dragging
  const handleDrag = (e) => {
    if (!isDragging) return;
    
    const containerWidth = document.querySelector('.main-content')?.offsetWidth || window.innerWidth;
    const maxWidth = containerWidth * 0.7; // Maximum 70% of container width
    const deltaX = startXRef.current - e.clientX;
    const newWidth = Math.min(Math.max(startWidthRef.current + deltaX, 200), maxWidth);
    
    setPreviewWidth(newWidth);
  };
  
  // Handle drag end
  const handleDragEnd = () => {
    setIsDragging(false);
    document.removeEventListener('mousemove', handleDrag);
    document.removeEventListener('mouseup', handleDragEnd);
  };
  
  // Setup click event listeners for checkboxes
  const setupCheckboxListeners = () => {
    const checkboxes = document.querySelectorAll('.preview-content input[type="checkbox"]');
    checkboxes.forEach(checkbox => {
      checkbox.addEventListener('change', handleCheckboxChange);
    });
  };
  
  // Handle checkbox click in preview
  const handleCheckboxChange = (e) => {
    const checkbox = e.target;
    const isChecked = checkbox.checked;
    
    // Create checkbox pattern to search for in the markdown
    const checkboxIndex = Array.from(
      document.querySelectorAll('.preview-content input[type="checkbox"]')
    ).indexOf(checkbox);
    
    if (checkboxIndex !== -1) {
      // Update the markdown content
      const lines = content.split('\n');
      let checkboxCount = -1;
      
      const newContent = lines.map(line => {
        if (line.match(/^\s*- \[ \]|\s*- \[x\]/i)) {
          checkboxCount++;
          
          if (checkboxCount === checkboxIndex) {
            return isChecked 
              ? line.replace(/\[ \]/i, '[x]') 
              : line.replace(/\[x\]/i, '[ ]');
          }
        }
        return line;
      }).join('\n');
      
      // Dispatch event to update content in the editor
      const event = new CustomEvent('checkboxChange', {
        detail: { newContent }
      });
      document.dispatchEvent(event);
    }
  };
  
  // Clean up event listeners when component unmounts
  useEffect(() => {
    return () => {
      const checkboxes = document.querySelectorAll('.preview-content input[type="checkbox"]');
      checkboxes.forEach(checkbox => {
        checkbox.removeEventListener('change', handleCheckboxChange);
      });
      document.removeEventListener('mousemove', handleDrag);
      document.removeEventListener('mouseup', handleDragEnd);
    };
  }, [html, isDragging]);
  
  return (
    <div 
      ref={previewRef}
      className="preview-wrapper" 
      style={{ width: `${previewWidth}px` }}
    >
      <div className="preview-drag-handle" onMouseDown={handleDragStart}></div>
      <div className="preview-header">
        <h3>Preview</h3>
      </div>
      <div 
        className="preview-content" 
        style={{
          fontFamily: settings.fontFamily,
          fontSize: `${settings.fontSize}px`,
          lineHeight: settings.lineHeight
        }}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </div>
  );
}
