import { useSettings } from '../context/SettingsContext';
import { useTabs } from '../context/TabsContext';
import { useTheme } from '../context/ThemeContext';

/**
 * Toolbar component with formatting controls and tools
 */
export function Toolbar({ 
  toggleFindReplace, 
  toggleFocusMode, 
  toggleTableBuilder, 
  toggleImageInserter 
}) {
  const { settings, updateSetting } = useSettings();
  const { activeTabId, updateTabContent, getActiveTab, exportTab } = useTabs();
  const { theme } = useTheme();
  
  // Get the active tab
  const activeTab = getActiveTab();
  
  // Toggle preview display
  const togglePreview = () => {
    updateSetting('showPreview', !settings.showPreview);
  };
  
  // Insert formatted text at cursor position
  const insertFormatting = (format) => {
    if (!activeTabId) return;
    
    const textarea = document.querySelector('.editor-textarea');
    if (!textarea) return;
    
    const selStart = textarea.selectionStart;
    const selEnd = textarea.selectionEnd;
    const content = textarea.value;
    const selectedText = content.substring(selStart, selEnd);
    
    let newContent = content;
    let newCursorPos = selEnd;
    
    switch (format) {
      case 'bold':
        newContent = content.substring(0, selStart) + `**${selectedText || 'bold text'}**` + content.substring(selEnd);
        newCursorPos = selStart + 2 + (selectedText ? selectedText.length : 9);
        break;
        
      case 'italic':
        newContent = content.substring(0, selStart) + `*${selectedText || 'italic text'}*` + content.substring(selEnd);
        newCursorPos = selStart + 1 + (selectedText ? selectedText.length : 11);
        break;
        
      case 'code':
        newContent = content.substring(0, selStart) + '`' + (selectedText || 'code') + '`' + content.substring(selEnd);
        newCursorPos = selStart + 1 + (selectedText ? selectedText.length : 4);
        break;
        
      case 'link':
        newContent = content.substring(0, selStart) + 
          `[${selectedText || 'link text'}](url)` + 
          content.substring(selEnd);
        newCursorPos = selStart + selectedText.length + 3;
        break;
        
      case 'image':
        newContent = content.substring(0, selStart) + 
          `![${selectedText || 'alt text'}](image-url)` + 
          content.substring(selEnd);
        newCursorPos = selStart + selectedText.length + 4;
        break;
        
      case 'heading':
        // Check if on a new line, if not, insert a new line
        const beforeSelection = content.substring(0, selStart);
        const needsNewLine = beforeSelection.length > 0 && !beforeSelection.endsWith('\n');
        newContent = content.substring(0, selStart) + 
          (needsNewLine ? '\n' : '') + 
          `## ${selectedText || 'Heading'}` + 
          content.substring(selEnd);
        newCursorPos = selStart + (needsNewLine ? 1 : 0) + 3 + (selectedText ? selectedText.length : 7);
        break;
        
      case 'list':
        // Check if on a new line, if not, insert a new line
        const beforeListSelection = content.substring(0, selStart);
        const needsListNewLine = beforeListSelection.length > 0 && !beforeListSelection.endsWith('\n');
        newContent = content.substring(0, selStart) + 
          (needsListNewLine ? '\n' : '') + 
          `- ${selectedText || 'List item'}` + 
          content.substring(selEnd);
        newCursorPos = selStart + (needsListNewLine ? 1 : 0) + 2 + (selectedText ? selectedText.length : 9);
        break;
        
      case 'checklist':
        // Check if on a new line, if not, insert a new line
        const beforeChecklistSelection = content.substring(0, selStart);
        const needsChecklistNewLine = beforeChecklistSelection.length > 0 && !beforeChecklistSelection.endsWith('\n');
        newContent = content.substring(0, selStart) + 
          (needsChecklistNewLine ? '\n' : '') + 
          `- [ ] ${selectedText || 'Task'}` + 
          content.substring(selEnd);
        newCursorPos = selStart + (needsChecklistNewLine ? 1 : 0) + 6 + (selectedText ? selectedText.length : 4);
        break;
        
      case 'quote':
        // Check if on a new line, if not, insert a new line
        const beforeQuoteSelection = content.substring(0, selStart);
        const needsQuoteNewLine = beforeQuoteSelection.length > 0 && !beforeQuoteSelection.endsWith('\n');
        newContent = content.substring(0, selStart) + 
          (needsQuoteNewLine ? '\n' : '') + 
          `> ${selectedText || 'Blockquote text'}` + 
          content.substring(selEnd);
        newCursorPos = selStart + (needsQuoteNewLine ? 1 : 0) + 2 + (selectedText ? selectedText.length : 14);
        break;
    }
    
    // Update content
    updateTabContent(activeTabId, newContent);
    
    // Update cursor position after render
    setTimeout(() => {
      textarea.focus();
      textarea.setSelectionRange(newCursorPos, newCursorPos);
    }, 0);
  };
  
  // Export the current tab
  const handleExport = () => {
    if (activeTabId) {
      exportTab(activeTabId, settings.defaultExportFormat);
    }
  };
  
  return (
    <div className="toolbar">
      <div className="formatting-buttons">
        <button 
          onClick={() => insertFormatting('bold')} 
          title="Bold (Ctrl+B)"
          aria-label="Bold"
        >
          <i className="fas fa-bold"></i>
        </button>
        
        <button 
          onClick={() => insertFormatting('italic')} 
          title="Italic (Ctrl+I)"
          aria-label="Italic"
        >
          <i className="fas fa-italic"></i>
        </button>
        
        <button 
          onClick={() => insertFormatting('heading')} 
          title="Heading (Ctrl+H)"
          aria-label="Heading"
        >
          <i className="fas fa-heading"></i>
        </button>
        
        <button 
          onClick={() => insertFormatting('link')} 
          title="Link (Ctrl+K)"
          aria-label="Link"
        >
          <i className="fas fa-link"></i>
        </button>
        
        <button 
          onClick={() => insertFormatting('image')} 
          title="Image"
          aria-label="Image"
        >
          <i className="fas fa-image"></i>
        </button>
        
        <button 
          onClick={() => insertFormatting('code')} 
          title="Code (Ctrl+`)"
          aria-label="Code"
        >
          <i className="fas fa-code"></i>
        </button>
        
        <button 
          onClick={() => insertFormatting('list')} 
          title="List"
          aria-label="List"
        >
          <i className="fas fa-list-ul"></i>
        </button>
        
        <button 
          onClick={() => insertFormatting('quote')} 
          title="Quote"
          aria-label="Quote"
        >
          <i className="fas fa-quote-right"></i>
        </button>
        
        <button 
          onClick={() => insertFormatting('checklist')} 
          title="Checklist"
          aria-label="Checklist"
        >
          <i className="fas fa-tasks"></i>
        </button>
        
        <button 
          onClick={toggleTableBuilder} 
          title="Table"
          aria-label="Table"
        >
          <i className="fas fa-table"></i>
        </button>

        <button 
          onClick={toggleImageInserter}
          title="Insert Image"
          aria-label="Insert Image"
        >
          <i className="fas fa-image"></i>
        </button>
      </div>
      
      <div className="toolbar-actions">
        <button 
          onClick={toggleFindReplace} 
          title="Find & Replace (Ctrl+F)"
          aria-label="Find and Replace"
        >
          <i className="fas fa-search"></i>
        </button>
        
        <button 
          onClick={togglePreview} 
          title={settings.showPreview ? "Hide Preview" : "Show Preview"}
          className={settings.showPreview ? "active" : ""}
          aria-label="Toggle Preview"
          aria-pressed={settings.showPreview}
        >
          <i className="fas fa-eye"></i>
        </button>
        
        <button 
          onClick={toggleFocusMode} 
          title="Focus Mode (F11)"
          aria-label="Focus Mode"
        >
          <i className="fas fa-expand"></i>
        </button>
        
        <button 
          onClick={handleExport} 
          title="Export"
          aria-label="Export"
          disabled={!activeTabId}
        >
          <i className="fas fa-download"></i>
        </button>
      </div>
    </div>
  );
}
