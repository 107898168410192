import { useTabs } from '../context/TabsContext';
import { useSettings } from '../context/SettingsContext';

/**
 * Footer component for displaying status information
 */
export function Footer({ togglePrivacy }) {
  const { getActiveTab } = useTabs();
  const { settings } = useSettings();
  
  // Get the active tab
  const activeTab = getActiveTab();
  
  // Calculate word and character count
  const getStats = () => {
    if (!activeTab || !activeTab.content) {
      return { words: 0, chars: 0, lines: 0 };
    }
    
    const content = activeTab.content;
    const chars = content.length;
    const words = content.trim() ? content.trim().split(/\s+/).length : 0;
    const lines = content.split('\n').length;
    
    return { words, chars, lines };
  };
  
  // Calculate reading time
  const getReadingTime = () => {
    if (!activeTab || !activeTab.content || !settings.readingTimeEnabled) {
      return null;
    }
    
    const wordsPerMinute = 200;
    const { words } = getStats();
    const minutes = Math.ceil(words / wordsPerMinute);
    
    return `${minutes} min read`;
  };
  
  // Get last modified time
  const getLastModified = () => {
    if (!activeTab || !activeTab.modifiedAt) {
      return null;
    }
    
    const date = new Date(activeTab.modifiedAt);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  
  // Quotes for display in the footer
  const quotes = [
    "The best time to write was yesterday. The next best time is now.",
    "Write hard and clear about what hurts. — Ernest Hemingway",
    "Good writing is rewriting. — Truman Capote",
    "First, write for yourself, then worry about the audience. — William Zinsser",
    "Start writing, no matter what. The water does not flow until the faucet is turned on. — Louis L'Amour",
    "The first draft is just you telling yourself the story. — Terry Pratchett",
    "A word after a word after a word is power. — Margaret Atwood",
    "Write what should not be forgotten. — Isabel Allende",
    "If it sounds like writing, I rewrite it. — Elmore Leonard",
    "You can always edit a bad page. You can't edit a blank page. — Jodi Picoult"
  ];
  
  // Get a random quote
  const getRandomQuote = () => {
    if (!settings.quotesEnabled) return null;
    const randomIndex = Math.floor(Math.random() * quotes.length);
    return quotes[randomIndex];
  };
  
  const stats = getStats();
  const readingTime = getReadingTime();
  const lastModified = getLastModified();
  const quote = getRandomQuote();
  
  return (
    <footer className="app-footer">
      <div className="stats">
        <span className="stat">{stats.words} words</span>
        <span className="stat">{stats.chars} characters</span>
        <span className="stat">{stats.lines} lines</span>
        
        {readingTime && (
          <span className="stat reading-time">{readingTime}</span>
        )}
      </div>
      
      <div className="status">
        {quote && (
          <span className="quote">{quote}</span>
        )}
        {lastModified && (
          <span className="last-modified">Last edited: {lastModified}</span>
        )}
        <span className="app-version">123Notepad v1.0</span>
      </div>
      
      <div className="footer-links">
        <button onClick={togglePrivacy} className="link-button" title="Privacy Policy">
          Privacy
        </button>
      </div>
    </footer>
  );
}
