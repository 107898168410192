import { useTheme } from '../context/ThemeContext';

/**
 * Header component for the application
 */
export function Header({ toggleSettings, toggleHelp }) {
  const { theme, toggleTheme } = useTheme();
  
  return (
    <header className="app-header">
      <div className="logo">
        <i className="fas fa-edit"></i>
        <h1>123Notepad</h1>
      </div>
      
      <div className="header-actions">
        <button 
          onClick={toggleTheme}
          title={theme === 'dark' ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
          aria-label="Toggle theme"
        >
          <i className={theme === 'dark' ? 'fas fa-sun' : 'fas fa-moon'}></i>
        </button>
        
        <button
          onClick={toggleHelp}
          title="Help & About"
          aria-label="Open help and about panel"
        >
          <i className="fas fa-question-circle"></i>
        </button>

        <button 
          onClick={toggleSettings}
          title="Settings"
          aria-label="Open settings"
        >
          <i className="fas fa-cog"></i>
        </button>
      </div>
    </header>
  );
}
