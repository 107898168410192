import { useRef, useEffect, useState } from 'preact/hooks';
import { useTabs } from '../context/TabsContext';
import { useSettings } from '../context/SettingsContext';
import { SlashCommands } from './SlashCommands';

/**
 * Editor component for text editing
 */
export function Editor() {
  const { activeTabId, getActiveTab, updateTabContent, updateTabTitle } = useTabs();
  const { settings } = useSettings();
  const textareaRef = useRef(null);
  
  // Slash commands state
  const [slashCommandsVisible, setSlashCommandsVisible] = useState(false);
  const [slashCommandsPosition, setSlashCommandsPosition] = useState({ top: 0, left: 0 });
  const [slashFilterText, setSlashFilterText] = useState('');
  
  // Available slash commands
  const slashCommands = [
    { id: 'bold', name: 'bold', icon: 'fa-bold', description: 'Make text bold' },
    { id: 'italic', name: 'italic', icon: 'fa-italic', description: 'Make text italic' },
    { id: 'heading1', name: 'h1', icon: 'fa-heading', description: 'Heading 1' },
    { id: 'heading2', name: 'h2', icon: 'fa-heading', description: 'Heading 2' },
    { id: 'heading3', name: 'h3', icon: 'fa-heading', description: 'Heading 3' },
    { id: 'link', name: 'link', icon: 'fa-link', description: 'Insert link' },
    { id: 'image', name: 'image', icon: 'fa-image', description: 'Insert image' },
    { id: 'code', name: 'code', icon: 'fa-code', description: 'Insert code' },
    { id: 'codeblock', name: 'codeblock', icon: 'fa-file-code', description: 'Insert code block' },
    { id: 'quote', name: 'quote', icon: 'fa-quote-left', description: 'Insert quote' },
    { id: 'list', name: 'list', icon: 'fa-list-ul', description: 'Unordered list' },
    { id: 'numbered', name: 'numbered', icon: 'fa-list-ol', description: 'Ordered list' },
    { id: 'task', name: 'task', icon: 'fa-check-square', description: 'Task list' },
    { id: 'table', name: 'table', icon: 'fa-table', description: 'Insert table' },
    { id: 'hr', name: 'hr', icon: 'fa-minus', description: 'Horizontal rule' },
    { id: 'clear', name: 'clear', icon: 'fa-trash', description: 'Clear formatting' },
  ];
  
  // Get the active tab
  const activeTab = getActiveTab();
  
  // State to force re-render on tab change
  const [editorKey, setEditorKey] = useState(activeTabId || 'default-key');
  
  // Set a new key whenever active tab changes to ensure clean re-render
  useEffect(() => {
    if (activeTabId) {
      setEditorKey(`editor-${activeTabId}`);
    }
  }, [activeTabId]);
  
  // Set focus to the editor when the key changes (after new textarea mounts)
  useEffect(() => {
    if (textareaRef.current) {
      setTimeout(() => {
        // Add extra safety check inside setTimeout
        if (textareaRef.current) { 
          textareaRef.current.focus();
        }
      }, 0);
    }
  }, [editorKey]); // Depend on editorKey instead of activeTabId
  
  // Handle editor content change
  const handleChange = (e) => {
    if (!activeTabId) return;
    
    const content = e.target.value;
    updateTabContent(activeTabId, content);
    
    // Check for slash commands
    checkForSlashCommands(e.target, content);
    
    // Auto-update tab title based on first line if eligible
    if (activeTab && (activeTab.title === `Tab ${activeTab.index}` || activeTab.autoTitle)) {
      const firstLine = content.split('\n')[0].trim();
      if (firstLine) {
        const title = firstLine.replace(/^#+ /, '').substring(0, 20);
        updateTabTitle(activeTabId, title, true);
      }
    }
  };
  
  // Check if the user has typed a "/" to trigger slash commands
  const checkForSlashCommands = (textarea, content) => {
    if (!slashCommandsVisible) return;
    
    const cursorPosition = textarea.selectionStart;
    
    // Check if we're at the start of a line or after a space
    const textBeforeCursor = content.substring(0, cursorPosition);
    const lastNewlineIndex = textBeforeCursor.lastIndexOf('\n');
    const lineStart = lastNewlineIndex === -1 ? 0 : lastNewlineIndex + 1;
    const textInCurrentLine = textBeforeCursor.substring(lineStart);
    
    // Get the text after the last slash
    const lastSlashIndex = textInCurrentLine.lastIndexOf('/');
    if (lastSlashIndex === -1) {
      setSlashCommandsVisible(false);
      return;
    }
    
    const filterText = textInCurrentLine.substring(lastSlashIndex + 1);
    
    // Update filter text for slash commands
    setSlashFilterText(filterText);
    
    // If the user deleted the slash, hide the commands
    if (!textInCurrentLine.includes('/')) {
      setSlashCommandsVisible(false);
    }
  };
  
  // Calculate position and show slash commands popup
  const showSlashCommandsPopup = (textarea, filterText) => {
    if (!textarea) return;
    
    const cursorPosition = textarea.selectionStart;
    const text = textarea.value.substring(0, cursorPosition);
    
    // Create a hidden div to measure text dimensions
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.visibility = 'hidden';
    div.style.whiteSpace = 'pre-wrap';
    div.style.wordWrap = 'break-word';
    div.style.overflow = 'hidden';
    div.style.fontSize = `${settings.fontSize}px`;
    div.style.fontFamily = settings.fontFamily;
    div.style.lineHeight = settings.lineHeight;
    div.style.padding = settings.editorPadding;
    div.textContent = text;
    
    document.body.appendChild(div);
    
    // Get cursor coordinates
    const lastLineBreak = text.lastIndexOf('\n');
    const lastLine = text.substring(lastLineBreak + 1);
    
    // Create a span for the last line to measure its height
    const span = document.createElement('span');
    span.textContent = lastLine;
    div.innerHTML = '';
    div.appendChild(span);
    
    // Get textarea's position and dimensions
    const textareaRect = textarea.getBoundingClientRect();
    
    // Get the position of the cursor
    const spanRect = span.getBoundingClientRect();
    
    // Clean up the temporary elements
    document.body.removeChild(div);
    
    // Calculate popup position
    const top = textareaRect.top + spanRect.height + 5; // Position below the cursor
    const left = textareaRect.left + span.offsetWidth;
    
    setSlashCommandsPosition({ top, left });
    setSlashFilterText(filterText);
    setSlashCommandsVisible(true);
  };
  
  // Handle slash command selection
  const handleCommandSelect = (command) => {
    if (!activeTabId || !textareaRef.current) return;
    
    const textarea = textareaRef.current;
    const cursorPosition = textarea.selectionStart;
    const cursorEndPosition = textarea.selectionEnd;
    const text = textarea.value;
    const selectedText = text.substring(cursorPosition, cursorEndPosition);
    
    // Find the start position of the slash command
    const textBeforeCursor = text.substring(0, cursorPosition);
    const lastNewlineIndex = textBeforeCursor.lastIndexOf('\n');
    const lineStart = lastNewlineIndex === -1 ? 0 : lastNewlineIndex + 1;
    const textInCurrentLine = textBeforeCursor.substring(lineStart);
    
    // Find the slash position
    const slashPos = textInCurrentLine.lastIndexOf('/');
    const slashAbsolutePos = lineStart + slashPos;
    
    // Prepare new content based on command
    let newContent;
    let newCursorPos;
    
    switch (command.id) {
      case 'bold':
        newContent = insertFormatting(text, slashAbsolutePos, cursorPosition, selectedText, '**', '**');
        newCursorPos = selectedText ? cursorPosition + 2 + selectedText.length : slashAbsolutePos + 2;
        break;
        
      case 'italic':
        newContent = insertFormatting(text, slashAbsolutePos, cursorPosition, selectedText, '_', '_');
        newCursorPos = selectedText ? cursorPosition + 1 + selectedText.length : slashAbsolutePos + 1;
        break;
        
      case 'heading1':
        newContent = insertFormatting(text, slashAbsolutePos, cursorPosition, selectedText, '# ', '');
        newCursorPos = selectedText ? cursorPosition + 2 + selectedText.length : slashAbsolutePos + 2;
        break;
        
      case 'heading2':
        newContent = insertFormatting(text, slashAbsolutePos, cursorPosition, selectedText, '## ', '');
        newCursorPos = selectedText ? cursorPosition + 3 + selectedText.length : slashAbsolutePos + 3;
        break;
        
      case 'heading3':
        newContent = insertFormatting(text, slashAbsolutePos, cursorPosition, selectedText, '### ', '');
        newCursorPos = selectedText ? cursorPosition + 4 + selectedText.length : slashAbsolutePos + 4;
        break;
        
      case 'link':
        newContent = insertFormatting(text, slashAbsolutePos, cursorPosition, selectedText, '[', '](url)');
        newCursorPos = selectedText ? cursorPosition + selectedText.length + 3 : slashAbsolutePos + 1;
        break;
        
      case 'image':
        newContent = insertFormatting(text, slashAbsolutePos, cursorPosition, selectedText, '![', '](url)');
        newCursorPos = selectedText ? cursorPosition + selectedText.length + 4 : slashAbsolutePos + 2;
        break;
        
      case 'code':
        newContent = insertFormatting(text, slashAbsolutePos, cursorPosition, selectedText, '`', '`');
        newCursorPos = selectedText ? cursorPosition + 1 + selectedText.length : slashAbsolutePos + 1;
        break;
        
      case 'codeblock':
        newContent = insertFormatting(text, slashAbsolutePos, cursorPosition, selectedText, '```\n', '\n```');
        newCursorPos = selectedText ? cursorPosition + 4 + selectedText.length : slashAbsolutePos + 4;
        break;
        
      case 'quote':
        newContent = insertFormatting(text, slashAbsolutePos, cursorPosition, selectedText, '> ', '');
        newCursorPos = selectedText ? cursorPosition + 2 + selectedText.length : slashAbsolutePos + 2;
        break;
        
      case 'list':
        newContent = insertFormatting(text, slashAbsolutePos, cursorPosition, selectedText, '- ', '');
        newCursorPos = selectedText ? cursorPosition + 2 + selectedText.length : slashAbsolutePos + 2;
        break;
        
      case 'numbered':
        newContent = insertFormatting(text, slashAbsolutePos, cursorPosition, selectedText, '1. ', '');
        newCursorPos = selectedText ? cursorPosition + 3 + selectedText.length : slashAbsolutePos + 3;
        break;
        
      case 'task':
        newContent = insertFormatting(text, slashAbsolutePos, cursorPosition, selectedText, '- [ ] ', '');
        newCursorPos = selectedText ? cursorPosition + 6 + selectedText.length : slashAbsolutePos + 6;
        break;
        
      case 'table':
        newContent = insertFormatting(text, slashAbsolutePos, cursorPosition, selectedText, 
          '| Header 1 | Header 2 |\n| --- | --- |\n| Cell 1 | Cell 2 |', '');
        newCursorPos = slashAbsolutePos + 8; // Position cursor at "Header 1"
        break;
        
      case 'hr':
        newContent = insertFormatting(text, slashAbsolutePos, cursorPosition, selectedText, '---\n', '');
        newCursorPos = slashAbsolutePos + 4;
        break;
        
      case 'clear':
        // This would require more complex logic to remove formatting - simplified for now
        newContent = text; 
        newCursorPos = cursorPosition;
        break;
        
      default:
        newContent = text;
        newCursorPos = cursorPosition;
    }
    
    // Update content
    updateTabContent(activeTabId, newContent);
    
    // Close slash commands popup
    setSlashCommandsVisible(false);
    
    // Set cursor position after timeout to ensure state is updated
    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(newCursorPos, newCursorPos);
      }
    }, 0);
  };
  
  // Helper function to insert formatting
  const insertFormatting = (text, slashPos, cursorPos, selectedText, prefix, suffix) => {
    // Replace the slash command with the formatted text
    const beforeSlash = text.substring(0, slashPos);
    const afterCursor = text.substring(cursorPos + (selectedText ? selectedText.length : 0));
    
    return beforeSlash + prefix + (selectedText || '') + suffix + afterCursor;
  };
  
  // Close slash commands popup
  const closeSlashCommands = () => {
    setSlashCommandsVisible(false);
  };
  
  // Handle key down events for auto-indentation and tab handling
  const handleKeyDown = (e) => {
    if (!activeTabId) return;
    
    // Detect slash key for slash commands
    if (e.key === '/' && !slashCommandsVisible) {
      const textarea = e.target;
      const cursorPosition = textarea.selectionStart;
      
      // Check if we're at the start of a line or after a space
      const textBeforeCursor = textarea.value.substring(0, cursorPosition);
      const lastNewlineIndex = textBeforeCursor.lastIndexOf('\n');
      const lineStart = lastNewlineIndex === -1 ? 0 : lastNewlineIndex + 1;
      const textInCurrentLine = textBeforeCursor.substring(lineStart);
      
      // Only show slash commands if at start of line or after a space
      if (textInCurrentLine === '' || textInCurrentLine.endsWith(' ')) {
        setTimeout(() => {
          showSlashCommandsPopup(textarea, '');
        }, 10);
      }
    }
    
    // Close slash commands with Escape key
    if (e.key === 'Escape' && slashCommandsVisible) {
      setSlashCommandsVisible(false);
    }
    
    // Handle tab key
    if (e.key === 'Tab') {
      e.preventDefault();
      const textarea = e.target;
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      
      // Current content
      const value = textarea.value;
      const selectedText = value.substring(start, end);
      
      let newContent;
      let newSelectionStart;
      let newSelectionEnd;
      
      // If there's a selection with multiple lines, indent all lines
      if (selectedText.includes('\n')) {
        const lines = selectedText.split('\n');
        const indentedLines = lines.map(line => `  ${line}`);
        const indentedText = indentedLines.join('\n');
        
        newContent = value.substring(0, start) + indentedText + value.substring(end);
        newSelectionStart = start;
        newSelectionEnd = start + indentedText.length;
      } else {
        // Simple case: insert 2 spaces at cursor
        newContent = value.substring(0, start) + '  ' + value.substring(end);
        newSelectionStart = start + 2;
        newSelectionEnd = start + 2;
      }
      
      // Update content
      updateTabContent(activeTabId, newContent);
      
      // Set selection after update (needs to be after render)
      setTimeout(() => {
        textarea.setSelectionRange(newSelectionStart, newSelectionEnd);
      }, 0);
    }
    
    // Auto-indent on Enter
    if (e.key === 'Enter' && settings.autoIndent) {
      const textarea = e.target;
      const value = textarea.value;
      const start = textarea.selectionStart;
      
      // Find the start of the current line
      const lineStart = value.lastIndexOf('\n', start - 1) + 1;
      const line = value.substring(lineStart, start);
      
      // Check for list markers
      const listMatch = line.match(/^(\s*)([-*+]|\d+\.)\s+/);
      
      if (listMatch) {
        e.preventDefault();
        
        // Check if the current line is empty (just the list marker)
        const isEmptyListItem = line.trim().replace(/[-*+]|\d+\./, '').trim() === '';
        
        if (isEmptyListItem) {
          // End the list by removing the empty item
          const newContent = value.substring(0, lineStart) + value.substring(start);
          updateTabContent(activeTabId, newContent);
          
          // Set cursor at the start of the new line
          setTimeout(() => {
            textarea.setSelectionRange(lineStart, lineStart);
          }, 0);
        } else {
          // Continue the list
          const indent = listMatch[1];
          const marker = listMatch[2];
          
          // For numbered lists, increment the number
          let newMarker = marker;
          if (/^\d+\./.test(marker)) {
            const num = parseInt(marker, 10);
            newMarker = `${num + 1}.`;
          }
          
          const insertion = `\n${indent}${newMarker} `;
          const newContent = value.substring(0, start) + insertion + value.substring(start);
          updateTabContent(activeTabId, newContent);
          
          // Set cursor at the end of the newly inserted line
          setTimeout(() => {
            textarea.setSelectionRange(start + insertion.length, start + insertion.length);
          }, 0);
        }
      } else {
        // Handle regular indentation - preserve indentation from previous line
        const indentMatch = line.match(/^(\s+)/);
        if (indentMatch) {
          e.preventDefault();
          
          const indent = indentMatch[1];
          const insertion = `\n${indent}`;
          const newContent = value.substring(0, start) + insertion + value.substring(start);
          updateTabContent(activeTabId, newContent);
          
          // Set cursor at the end of the indentation on the new line
          setTimeout(() => {
            textarea.setSelectionRange(start + insertion.length, start + insertion.length);
          }, 0);
        }
      }
    }
  };
  
  // Listen for checkbox changes from the Preview component
  useEffect(() => {
    const handleCheckboxChange = (e) => {
      if (activeTabId) {
        updateTabContent(activeTabId, e.detail.newContent);
      }
    };
    
    document.addEventListener('checkboxChange', handleCheckboxChange);
    return () => {
      document.removeEventListener('checkboxChange', handleCheckboxChange);
    };
  }, [activeTabId, updateTabContent]);
  
  // Apply styles from settings
  const editorStyle = {
    fontFamily: settings.fontFamily,
    fontSize: settings.fontSize,
    lineHeight: settings.lineHeight,
    padding: settings.editorPadding,
    whiteSpace: settings.wordWrap ? 'pre-wrap' : 'pre'
  };
  
  // Use a key to force clean re-render on tab change
  return (
    <>
      <textarea
        key={editorKey}
        ref={textareaRef}
        className="editor-textarea"
        style={editorStyle}
        value={activeTab ? activeTab.content : ''}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Start typing here..."
        data-tab-id={activeTabId}
      />
      
      <SlashCommands
        isVisible={slashCommandsVisible}
        position={slashCommandsPosition}
        commands={slashCommands}
        onCommandSelect={handleCommandSelect}
        filterText={slashFilterText}
        onClose={closeSlashCommands}
      />
    </>
  );
}
