import { useState, useEffect } from 'preact/hooks';
import { createPortal } from 'preact/compat';

/**
 * PomodoroTimer component that adds a customizable timer functionality
 * with a reliable modal-style dropdown
 */
export function PomodoroTimer() {
  const [activeTimer, setActiveTimer] = useState(null);
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [showModal, setShowModal] = useState(false);
  
  const timerOptions = [
    { name: '5m', seconds: 5 * 60 },
    { name: '15m', seconds: 15 * 60 },
    { name: '30m', seconds: 30 * 60 },
    { name: '60m', seconds: 60 * 60 }
  ];
  
  // Effect to handle timer countdown
  useEffect(() => {
    let interval = null;
    
    if (isRunning && time > 0) {
      interval = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);
    } else if (time === 0 && isRunning) {
      setIsRunning(false);
      if ('Notification' in window && Notification.permission === 'granted') {
        new Notification('Timer Complete', {
          body: 'Your timer has finished!'
        });
      }
    }
    
    return () => clearInterval(interval);
  }, [isRunning, time]);
  
  // Format seconds to MM:SS
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };
  
  // Start a timer with specified duration
  const startTimer = (seconds) => {
    setTime(seconds);
    setIsRunning(true);
    setActiveTimer(seconds);
    setShowModal(false);
  };
  
  // Toggle between play/pause
  const toggleTimer = () => {
    setIsRunning(!isRunning);
  };
  
  // Reset timer to original duration
  const resetTimer = () => {
    setIsRunning(false);
    setTime(activeTimer);
  };
  
  // Calculate progress percentage for display
  const calculateProgress = () => {
    if (!activeTimer) return 0;
    return ((activeTimer - time) / activeTimer) * 100;
  };
  
  // Calculate the circular progress indicator values
  const progress = calculateProgress();
  const circumference = 2 * Math.PI * 16; // Circle circumference (2πr)
  const strokeDashoffset = circumference * (1 - progress / 100);
  
  // Handle clicks outside of modal to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showModal && 
          !event.target.closest('.timer-container') &&
          !event.target.closest('.timer-modal')) {
        setShowModal(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModal]);

  // Modal content rendering
  const renderTimerModal = () => {
    if (!showModal) return null;
    
    return createPortal(
      <div className="timer-modal-backdrop">
        <div className="timer-modal">
          <div className="timer-modal-header">
            <h3>Pomodoro Timer</h3>
            <button 
              className="timer-modal-close" 
              onClick={() => setShowModal(false)}
            >
              &times;
            </button>
          </div>
          
          <div className="timer-modal-content">
            <h4 className="timer-modal-subheader">Select Timer Duration</h4>
            <div className="timer-modal-grid">
              {timerOptions.map((option) => (
                <button
                  key={option.name}
                  onClick={() => startTimer(option.seconds)}
                  className="timer-modal-option"
                >
                  {option.name}
                </button>
              ))}
            </div>
            
            {activeTimer && (
              <div className="timer-modal-footer">
                <div className="timer-modal-time">{formatTime(time)}</div>
                <div className="timer-modal-actions">
                  <button 
                    onClick={toggleTimer}
                    className="timer-modal-action"
                  >
                    {isRunning ? 'Pause' : 'Resume'}
                  </button>
                  <button 
                    onClick={resetTimer}
                    className="timer-modal-action"
                  >
                    Reset
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>,
      document.body
    );
  };
  
  return (
    <div className="timer-container">
      {/* Timer Button */}
      <div 
        onClick={() => setShowModal(true)}
        className={`planner-tab timer-button ${isRunning ? 'active' : ''}`}
        title="Pomodoro Timer"
      >
        <i className="fas fa-hourglass-half mr-2"></i>
        <span className="tab-title">
          {activeTimer ? formatTime(time) : "Timer"}
        </span>
      </div>
      
      {/* Render timer modal */}
      {renderTimerModal()}
    </div>
  );
}

export default PomodoroTimer;
