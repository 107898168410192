import { useState, useEffect, useRef } from 'preact/hooks';
import { useTabs } from '../context/TabsContext';
import { useSettings } from '../context/SettingsContext';

/**
 * FocusMode component for distraction-free writing
 */
export function FocusMode({ onExit }) {
  const { activeTabId, updateTabContent } = useTabs();
  const { settings } = useSettings();
  const textareaRef = useRef(null);
  const [content, setContent] = useState('');
  
  // Get initial content
  useEffect(() => {
    const textarea = document.querySelector('.editor-textarea');
    if (textarea) {
      setContent(textarea.value);
      
      // Get cursor position
      const selStart = textarea.selectionStart;
      const selEnd = textarea.selectionEnd;
      
      // Set cursor position after render
      setTimeout(() => {
        if (textareaRef.current) {
          textareaRef.current.focus();
          textareaRef.current.setSelectionRange(selStart, selEnd);
        }
      }, 0);
    }
  }, []);
  
  // Handle content change
  const handleChange = (e) => {
    const newContent = e.target.value;
    setContent(newContent);
    
    // Update tab content
    if (activeTabId) {
      updateTabContent(activeTabId, newContent);
    }
  };
  
  // Handle escape key to exit focus mode
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onExit();
      }
    };
    
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onExit]);
  
  // Apply styles from settings
  const editorStyle = {
    fontFamily: settings.fontFamily,
    fontSize: settings.fontSize,
    lineHeight: settings.lineHeight,
    padding: settings.editorPadding,
    whiteSpace: settings.wordWrap ? 'pre-wrap' : 'pre'
  };
  
  return (
    <div className="focus-mode">
      <div className="focus-header">
        <h2>Focus Mode</h2>
        <button 
          onClick={onExit}
          className="exit-focus"
          aria-label="Exit focus mode"
        >
          <i className="fas fa-compress"></i>
        </button>
      </div>
      
      <textarea
        ref={textareaRef}
        className="focus-textarea"
        style={editorStyle}
        value={content}
        onChange={handleChange}
        placeholder="Start typing here..."
      />
      
      <div className="focus-footer">
        <span className="focus-message">Press ESC to exit focus mode</span>
      </div>
    </div>
  );
}
