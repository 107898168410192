import { useState, useEffect } from 'preact/hooks';
import { useTabs } from '../context/TabsContext';

/**
 * Find and Replace component
 */
export function FindReplace({ onClose }) {
  const { activeTabId, getActiveTab, updateTabContent } = useTabs();
  const [findText, setFindText] = useState('');
  const [replaceText, setReplaceText] = useState('');
  const [caseSensitive, setCaseSensitive] = useState(false);
  const [matchCount, setMatchCount] = useState(0);
  const [currentMatch, setCurrentMatch] = useState(0);
  
  // Get text content from active tab
  const getContent = () => {
    const activeTab = getActiveTab();
    return activeTab ? activeTab.content : '';
  };
  
  // Find all matches in the content
  const findMatches = (searchText, content, isCaseSensitive) => {
    if (!searchText || !content) return [];
    
    const regex = new RegExp(
      searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 
      isCaseSensitive ? 'g' : 'gi'
    );
    
    const matches = [];
    let match;
    
    while ((match = regex.exec(content)) !== null) {
      matches.push({
        index: match.index,
        length: match[0].length
      });
    }
    
    return matches;
  };
  
  // Update match count when find text changes
  useEffect(() => {
    if (findText) {
      const content = getContent();
      const matches = findMatches(findText, content, caseSensitive);
      setMatchCount(matches.length);
      setCurrentMatch(matches.length > 0 ? 1 : 0);
      
      // Highlight first match if there are any
      if (matches.length > 0) {
        highlightMatch(matches[0]);
      }
    } else {
      setMatchCount(0);
      setCurrentMatch(0);
      // Remove any selection
      const textarea = document.querySelector('.editor-textarea');
      if (textarea) {
        textarea.setSelectionRange(textarea.selectionStart, textarea.selectionStart);
      }
    }
  }, [findText, caseSensitive]);
  
  // Highlight a specific match in the editor
  const highlightMatch = (match) => {
    if (!match) return;
    
    const textarea = document.querySelector('.editor-textarea');
    if (textarea) {
      textarea.focus();
      textarea.setSelectionRange(match.index, match.index + match.length);
      
      // Scroll to the match if necessary
      const lineHeight = parseInt(getComputedStyle(textarea).lineHeight);
      const textBeforeMatch = textarea.value.substring(0, match.index);
      const lineCount = (textBeforeMatch.match(/\n/g) || []).length;
      
      // Estimate scroll position based on line count
      textarea.scrollTop = lineCount * lineHeight;
    }
  };
  
  // Find next match
  const findNext = () => {
    if (matchCount === 0) return;
    
    const content = getContent();
    const matches = findMatches(findText, content, caseSensitive);
    
    if (matches.length > 0) {
      const nextMatch = currentMatch % matches.length;
      setCurrentMatch(nextMatch + 1);
      highlightMatch(matches[nextMatch]);
    }
  };
  
  // Find previous match
  const findPrev = () => {
    if (matchCount === 0) return;
    
    const content = getContent();
    const matches = findMatches(findText, content, caseSensitive);
    
    if (matches.length > 0) {
      const prevMatch = (currentMatch - 2 + matches.length) % matches.length;
      setCurrentMatch(prevMatch + 1);
      highlightMatch(matches[prevMatch]);
    }
  };
  
  // Replace current match
  const replaceMatch = () => {
    if (matchCount === 0 || !activeTabId) return;
    
    const content = getContent();
    const matches = findMatches(findText, content, caseSensitive);
    
    if (matches.length > 0 && currentMatch > 0) {
      const match = matches[currentMatch - 1];
      const newContent = 
        content.substring(0, match.index) + 
        replaceText + 
        content.substring(match.index + match.length);
      
      updateTabContent(activeTabId, newContent);
      
      // Update matches after replacement
      setTimeout(() => {
        const updatedContent = getContent();
        const updatedMatches = findMatches(findText, updatedContent, caseSensitive);
        setMatchCount(updatedMatches.length);
        
        // Adjust current match index if needed
        if (currentMatch > updatedMatches.length) {
          setCurrentMatch(updatedMatches.length > 0 ? 1 : 0);
        }
        
        // Highlight next match if available
        if (updatedMatches.length > 0) {
          const nextMatchIndex = Math.min(currentMatch - 1, updatedMatches.length - 1);
          highlightMatch(updatedMatches[nextMatchIndex]);
        }
      }, 0);
    }
  };
  
  // Replace all matches
  const replaceAll = () => {
    if (matchCount === 0 || !activeTabId) return;
    
    const content = getContent();
    const regex = new RegExp(
      findText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 
      caseSensitive ? 'g' : 'gi'
    );
    
    const newContent = content.replace(regex, replaceText);
    updateTabContent(activeTabId, newContent);
    
    // Reset match state
    setMatchCount(0);
    setCurrentMatch(0);
    setFindText('');
    setReplaceText('');
  };
  
  // Handle key events
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Close on Escape
      if (e.key === 'Escape') {
        onClose();
      }
      
      // Find next on Enter
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        findNext();
      }
      
      // Find prev on Shift+Enter
      if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault();
        findPrev();
      }
    };
    
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [findNext, findPrev, onClose]);
  
  return (
    <div className="find-replace">
      <div className="find-replace-inputs">
        <div className="find-group">
          <input
            type="text"
            placeholder="Find"
            value={findText}
            onInput={(e) => setFindText(e.target.value)}
            autoFocus
          />
          <span className="match-counter">
            {matchCount > 0 ? `${currentMatch}/${matchCount}` : '0/0'}
          </span>
        </div>
        
        <div className="replace-group">
          <input
            type="text"
            placeholder="Replace"
            value={replaceText}
            onInput={(e) => setReplaceText(e.target.value)}
          />
        </div>
      </div>
      
      <div className="find-replace-controls">
        <div className="find-replace-options">
          <label>
            <input
              type="checkbox"
              checked={caseSensitive}
              onChange={() => setCaseSensitive(!caseSensitive)}
            />
            Case sensitive
          </label>
        </div>
        
        <div className="find-replace-buttons">
          <button onClick={findPrev} disabled={matchCount === 0}>
            <i className="fas fa-chevron-up"></i>
          </button>
          <button onClick={findNext} disabled={matchCount === 0}>
            <i className="fas fa-chevron-down"></i>
          </button>
          <button onClick={replaceMatch} disabled={matchCount === 0}>
            Replace
          </button>
          <button onClick={replaceAll} disabled={matchCount === 0}>
            Replace All
          </button>
          <button onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
