import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { useTheme } from '../context/ThemeContext';
import { useSettings } from '../context/SettingsContext';
import { useTabs } from '../context/TabsContext';
import { initClarity, trackPageView } from '../utils/clarity';

// Components
import { TabBar } from './TabBar';
import { Toolbar } from './Toolbar';
import { Editor } from './Editor';
import { Preview } from './Preview';
import { SettingsPanel } from './SettingsPanel';
import { FindReplace } from './FindReplace';
import { FocusMode } from './FocusMode';
import { Footer } from './Footer';
import { TableBuilder } from './TableBuilder';
import { ImageInserter } from './ImageInserter';
import { HelpPanel } from './HelpPanel'; 
import { PrivacyPanel } from './PrivacyPanel'; 
import { Header } from './Header';
import { Planner } from './Planner';

import '../styles/global.css';

export function App() {
  // Context Hooks
  const { theme } = useTheme(); 
  const { settings } = useSettings(); 
  const { tabs, activeTabId, setActiveTabId, createNewTab, closeTab } = useTabs();
  
  // Initialize Microsoft Clarity
  useEffect(() => {
    // Temporarily commented out to fix other functionality first
    // initClarity('qzknzcbef6');
  }, []);
  
  // Track page views when active tab changes
  useEffect(() => {
    // Temporarily commented out to fix other functionality first
    /*
    if (activeTabId) {
      const activeTab = tabs.find(tab => tab.id === activeTabId);
      const tabName = activeTab ? activeTab.title : 'Untitled';
      trackPageView(`Tab: ${tabName}`);
    }
    */
  }, [activeTabId, tabs]);
  
  // Local State for UI Panels/Modes
  const [settingsPanelVisible, setSettingsPanelVisible] = useState(false);
  const [findReplaceVisible, setFindReplaceVisible] = useState(false);
  const [focusModeActive, setFocusModeActive] = useState(false);
  const [tableBuilderVisible, setTableBuilderVisible] = useState(false);
  const [imageInserterVisible, setImageInserterVisible] = useState(false);
  const [helpPanelVisible, setHelpPanelVisible] = useState(false); 
  const [privacyPanelVisible, setPrivacyPanelVisible] = useState(false); 
  
  // Toggle Handlers for UI Panels/Modes
  const toggleSettings = () => setSettingsPanelVisible(!settingsPanelVisible);
  const toggleFindReplace = () => setFindReplaceVisible(!findReplaceVisible);
  const toggleFocusMode = () => setFocusModeActive(!focusModeActive);
  const toggleTableBuilder = () => setTableBuilderVisible(!tableBuilderVisible);
  const toggleImageInserter = () => setImageInserterVisible(!imageInserterVisible);
  const toggleHelpPanel = () => setHelpPanelVisible(!helpPanelVisible); 
  const togglePrivacyPanel = () => setPrivacyPanelVisible(!privacyPanelVisible); 
  
  // Get the active tab content for Editor and Preview
  const activeTab = tabs.find(tab => tab.id === activeTabId);
  const editorContent = activeTab ? activeTab.content : '';
  
  // Check if the planner tab is active
  const isPlannerActive = activeTab && activeTab.isPlanner;

  // Dynamic class for dark mode
  const appContainerClass = `app-container ${theme === 'dark' ? 'dark' : ''} ${focusModeActive ? 'focus-mode' : ''}`;

  return (
    <div className={appContainerClass}>
      {/* Pass HelpPanel toggle to Header */}
      <Header toggleSettings={toggleSettings} toggleHelp={toggleHelpPanel} />
      
      {/* Toolbar Component - Only show when Planner is not active */}
      {!isPlannerActive && (
        <Toolbar
          toggleFindReplace={toggleFindReplace}
          toggleFocusMode={toggleFocusMode}
          toggleTableBuilder={toggleTableBuilder} 
          toggleImageInserter={toggleImageInserter}
        />
      )}

      {/* TabBar Component */}
      <TabBar
        tabs={tabs}
        activeTabId={activeTabId}
        onSelectTab={setActiveTabId}
        onCloseTab={closeTab}
        onNewTab={createNewTab} 
        settings={settings} 
      />

      {/* Main Content Area */}    
      <div className="main-content">
        {isPlannerActive ? (
          // Show Planner when the planner tab is active
          <Planner />
        ) : (
          // Show Editor and Preview for normal tabs
          <>
            <Editor /> 
            {settings.showPreview && <Preview content={editorContent} />}
          </>
        )}
      </div>

      {/* Pass PrivacyPanel toggle to Footer */}
      <Footer togglePrivacy={togglePrivacyPanel} />

      {/* Settings Panel (Conditional Rendering) */}
      {settingsPanelVisible && (
        <SettingsPanel 
          onClose={toggleSettings} 
        />
      )}

      {/* Find/Replace Panel (Conditional Rendering) */}
      {findReplaceVisible && 
        <FindReplace 
          onClose={toggleFindReplace} 
        />
      }

      {/* Focus Mode Overlay (Conditional Rendering) */}
      {focusModeActive && 
        <FocusMode onExit={toggleFocusMode} />
      }

      {/* Table Builder (Conditional Rendering) */}
      {tableBuilderVisible && (
        <TableBuilder
          isOpen={tableBuilderVisible}
          onClose={toggleTableBuilder}
        />
      )}

      {/* Image Inserter (Conditional Rendering) */}
      {imageInserterVisible && (
        <ImageInserter
          isOpen={imageInserterVisible}
          onClose={toggleImageInserter}
        />
      )}

      {/* Help Panel (Conditional Rendering) */}
      {helpPanelVisible && (
        <HelpPanel onClose={toggleHelpPanel} />
      )}

      {/* Privacy Panel (Conditional Rendering) */}
      {privacyPanelVisible && (
        <PrivacyPanel onClose={togglePrivacyPanel} />
      )}
    </div>
  );
}
