import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

/**
 * HelpPanel component to display help documentation from Markdown.
 */
export function HelpPanel({ onClose }) {
  const [helpContent, setHelpContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch Markdown content from the file in the public directory
    fetch('/docs/help.md')
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then(text => {
        // Parse Markdown and sanitize HTML
        const rawHtml = marked.parse(text);
        const cleanHtml = DOMPurify.sanitize(rawHtml);
        setHelpContent(cleanHtml);
        setIsLoading(false);
      })
      .catch(err => {
        console.error("Error fetching or parsing help.md:", err);
        setError('Failed to load help content. Please try again later.');
        setIsLoading(false);
      });
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div className="modal" onClick={onClose}> {/* Close on overlay click */}
      <div className="modal-content help-panel" onClick={(e) => e.stopPropagation()}> {/* Prevent closing when clicking inside */}
        <div className="settings-header"> {/* Reuse settings header style */}
          <h2>Help & About</h2>
          <button onClick={onClose} className="close-button" aria-label="Close Help">×</button>
        </div>

        <div className="settings-body help-body"> {/* Reuse settings body style */}
          {isLoading && <p>Loading help content...</p>}
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {!isLoading && !error && (
            <div dangerouslySetInnerHTML={{ __html: helpContent }}></div>
          )}
        </div>
        
        <div className="settings-footer"> {/* Reuse settings footer style */}
           <button onClick={onClose} className="settings-button">Close</button>
        </div>
      </div>
    </div>
  );
}
