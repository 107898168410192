import { h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';

/**
 * SlashCommands component - Shows formatting commands when typing "/"
 */
export function SlashCommands({ 
  isVisible, 
  position, 
  commands, 
  onCommandSelect, 
  filterText,
  onClose 
}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [filteredCommands, setFilteredCommands] = useState(commands);
  const commandsRef = useRef(null);
  const activeItemRef = useRef(null);

  // Filter commands based on user input after the "/"
  useEffect(() => {
    if (!filterText) {
      setFilteredCommands(commands);
      return;
    }
    
    const filtered = commands.filter(cmd => 
      cmd.name.toLowerCase().includes(filterText.toLowerCase()) || 
      cmd.description.toLowerCase().includes(filterText.toLowerCase())
    );
    
    setFilteredCommands(filtered);
    setActiveIndex(0); // Reset selection when filter changes
  }, [filterText, commands]);

  // Handle keyboard navigation (up/down arrows, enter, escape)
  const handleKeyDown = (e) => {
    if (!isVisible) return;
    
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setActiveIndex((prevIndex) => 
          prevIndex < filteredCommands.length - 1 ? prevIndex + 1 : prevIndex
        );
        break;
        
      case 'ArrowUp':
        e.preventDefault();
        setActiveIndex((prevIndex) => prevIndex > 0 ? prevIndex - 1 : 0);
        break;
        
      case 'Enter':
        e.preventDefault();
        if (filteredCommands[activeIndex]) {
          onCommandSelect(filteredCommands[activeIndex]);
        }
        break;
        
      case 'Escape':
        e.preventDefault();
        onClose();
        break;
        
      default:
        return;
    }
  };

  // Scroll active item into view when it changes
  useEffect(() => {
    if (activeItemRef.current && commandsRef.current) {
      activeItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [activeIndex]);

  // Add keyboard event listener
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isVisible, filteredCommands, activeIndex]);

  // Don't render if not visible
  if (!isVisible) return null;

  return (
    <div 
      className="slash-commands-popup" 
      style={{ 
        top: `${position.top}px`, 
        left: `${position.left}px`,
      }}
    >
      <div className="slash-commands-header">
        <span className="slash-commands-title">Commands</span>
        <input 
          type="text" 
          className="slash-filter-input" 
          placeholder="Filter commands..."
          value={filterText} 
          autoFocus 
        />
      </div>
      {filteredCommands.length > 0 ? (
        <ul className="slash-commands-list" ref={commandsRef}>
          {filteredCommands.map((command, index) => (
            <li 
              key={command.id}
              className={`slash-command-item ${index === activeIndex ? 'active' : ''}`}
              onClick={() => onCommandSelect(command)}
              onMouseEnter={() => setActiveIndex(index)}
              ref={index === activeIndex ? activeItemRef : null}
            >
              <div className="slash-command-icon">
                <i className={`fas ${command.icon}`}></i>
              </div>
              <div className="slash-command-content">
                <div className="slash-command-name">/{command.name}</div>
                <div className="slash-command-description">{command.description}</div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <div className="slash-commands-empty">
          <p>No commands match your search</p>
        </div>
      )}
    </div>
  );
}
