import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

/**
 * Planner component for timeboxing and productivity
 */
export function Planner() {
  const currentDateRef = useRef(null);
  const [priorities, setPriorities] = useState(['', '', '']);
  const [brainDump, setBrainDump] = useState('');
  const [timeEntries, setTimeEntries] = useState({});
  const [splitView, setSplitView] = useState(true); // New state for toggle
  
  // Set the current date when component mounts
  useEffect(() => {
    if (currentDateRef.current) {
      const today = new Date();
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      currentDateRef.current.value = today.toLocaleDateString('en-US', options);
    }
    
    // Load saved planner data from localStorage
    try {
      const savedData = JSON.parse(localStorage.getItem('plannerData'));
      if (savedData) {
        if (savedData.priorities) setPriorities(savedData.priorities);
        if (savedData.brainDump) setBrainDump(savedData.brainDump);
        if (savedData.timeEntries) setTimeEntries(savedData.timeEntries);
        if (savedData.splitView !== undefined) setSplitView(savedData.splitView);
      }
    } catch (error) {
      console.error("Error loading planner data:", error);
    }
  }, []);
  
  // Save planner data to localStorage when it changes
  useEffect(() => {
    const plannerData = {
      priorities,
      brainDump,
      timeEntries,
      splitView
    };
    localStorage.setItem('plannerData', JSON.stringify(plannerData));
  }, [priorities, brainDump, timeEntries, splitView]);
  
  // Update a priority item
  const updatePriority = (index, value) => {
    const newPriorities = [...priorities];
    newPriorities[index] = value;
    setPriorities(newPriorities);
  };
  
  // Update a time entry
  const updateTimeEntry = (hour, minute, value) => {
    const key = `${hour}:${minute}`;
    setTimeEntries(prev => ({
      ...prev,
      [key]: value
    }));
  };
  
  // Toggle view between split and unified
  const toggleView = () => {
    setSplitView(!splitView);
  };
  
  // Generate time grid rows (Starting from 6 AM)
  const generateTimeRows = () => {
    const rows = [];
    for (let i = 6; i < 30; i++) {
      const hour = i % 24;
      const display = hour % 12 || 12; // Convert to 12-hour format
      const ampm = hour < 12 ? 'AM' : 'PM';
      
      const key00 = `${hour}:00`;
      const key30 = `${hour}:30`;
      
      if (splitView) {
        // Split view with :00 and :30 columns
        rows.push(
          <tr key={i}>
            <td className="hour-cell">{display} {ampm}</td>
            <td className="time-cell">
              <textarea 
                className="time-entry"
                value={timeEntries[key00] || ''}
                onChange={(e) => updateTimeEntry(hour, '00', e.target.value)}
              />
            </td>
            <td className="time-cell">
              <textarea 
                className="time-entry"
                value={timeEntries[key30] || ''}
                onChange={(e) => updateTimeEntry(hour, '30', e.target.value)}
              />
            </td>
          </tr>
        );
      } else {
        // Unified view (single row for the entire hour)
        rows.push(
          <tr key={i}>
            <td className="hour-cell">{display} {ampm}</td>
            <td className="time-cell full-hour" colSpan="2">
              <textarea 
                className="time-entry"
                value={`${timeEntries[key00] || ''}\n${timeEntries[key30] || ''}`.trim()}
                onChange={(e) => {
                  // Split text by newline and assign to respective time slots
                  const lines = e.target.value.split('\n');
                  updateTimeEntry(hour, '00', lines[0] || '');
                  updateTimeEntry(hour, '30', lines[1] || '');
                }}
              />
            </td>
          </tr>
        );
      }
    }
    return rows;
  };
  
  return (
    <div className="planner-container">
      <div className="planner-header">
        <div className="planner-quote-container">
          <p className="planner-quote">
            The key is not to prioritize what's on your schedule, but to schedule your priorities.
          </p>
        </div>
        
        <div className="planner-date-section">
          <label htmlFor="planner-date">Date: </label>
          <input 
            type="text" 
            id="planner-date" 
            className="planner-date-input"
            ref={currentDateRef}
          />
        </div>
      </div>
      
      <div className="planner-content">
        <div className="planner-left-column">
          <div className="priorities-section">
            <h3>Priorities</h3>
            <ul className="priority-list">
              {priorities.map((priority, index) => (
                <li key={index} className="priority-item">
                  <textarea
                    className="priority-input"
                    value={priority}
                    onChange={(e) => updatePriority(index, e.target.value)}
                    placeholder={`Priority ${index + 1}`}
                  />
                </li>
              ))}
            </ul>
          </div>
          
          <div className="brain-dump-section">
            <h3>Brain Dump</h3>
            <textarea 
              className="brain-dump-textarea" 
              value={brainDump}
              onChange={(e) => setBrainDump(e.target.value)}
              placeholder="Capture your thoughts here..."
            />
          </div>
        </div>
        
        <div className="planner-right-column">
          <div className="time-view-toggle-container">
            <span className="time-view-label">Split time slots</span>
            <label className="toggle-switch">
              <input 
                type="checkbox"
                checked={splitView}
                onChange={toggleView}
              />
              <span className="toggle-slider"></span>
            </label>
          </div>
          
          <table className="time-grid">
            <thead>
              <tr>
                <th>hr</th>
                {splitView ? (
                  <>
                    <th>:00</th>
                    <th>:30</th>
                  </>
                ) : (
                  <th colSpan="2">Hour</th>
                )}
              </tr>
            </thead>
            <tbody>
              {generateTimeRows()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
