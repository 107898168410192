import { useTabs } from '../context/TabsContext';
import { useSettings } from '../context/SettingsContext';
import { useState, useRef, useEffect } from 'preact/hooks';
import { PomodoroTimer } from './PomodoroTimer';

/**
 * Tab component that represents a single tab
 */
function Tab({ tab, isActive, onClick, onClose, onRename }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editTitle, setEditTitle] = useState(tab.title || `Tab ${tab.index}`);
  const inputRef = useRef(null);
  
  // Add planner-tab class if this is the planner tab
  const tabClass = `tab ${isActive ? 'active' : ''} ${tab.isPlanner ? 'planner-tab' : ''}`;
  
  // Update editTitle when tab.title changes (e.g., when tab is first loaded)
  useEffect(() => {
    if (!isEditing) {
      setEditTitle(tab.title || `Tab ${tab.index}`);
    }
  }, [tab.title, tab.index, isEditing]);
  
  const handleDoubleClick = (e) => {
    // Don't enable editing for planner tab
    if (tab.isPlanner) return;
    
    e.stopPropagation();
    setIsEditing(true);
    setEditTitle(tab.title || `Tab ${tab.index}`);
    // Focus the input after rendering
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, 10);
  };
  
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      finishEditing();
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setIsEditing(false);
      setEditTitle(tab.title || `Tab ${tab.index}`);
    }
  };
  
  const finishEditing = () => {
    // Always attempt to save the new title when editing is finished
    const newTitle = editTitle.trim();
    if (newTitle) { // Only check if the title is not empty
      console.log('Finishing edit with new title:', newTitle);
      onRename(newTitle);
    } else {
      // If empty, reset to the original title
      setEditTitle(tab.title || `Tab ${tab.index}`);
    }
    setIsEditing(false);
  };
  
  const handleBlur = () => {
    finishEditing();
  };
  
  const handleChange = (e) => {
    setEditTitle(e.target.value);
  };
  
  return (
    <div 
      className={tabClass}
      onClick={onClick}
      onDblClick={handleDoubleClick}
    >
      {isEditing ? (
        <>
          <input
            ref={inputRef}
            className="tab-title-input"
            type="text"
            value={editTitle}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            onClick={(e) => e.stopPropagation()}
            maxLength={25}
          />
          <button
            className="save-tab-name"
            onClick={(e) => {
              e.stopPropagation();
              finishEditing();
            }}
            title="Save tab name"
          >
            <i className="fas fa-check"></i>
          </button>
        </>
      ) : (
        <span className="tab-title">{tab.title || `Tab ${tab.index}`}</span>
      )}
      
      {!tab.isPlanner && !isEditing && (
        <button
          className="close-tab"
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          title="Close tab"
        >
          &times;
        </button>
      )}
    </div>
  );
}

/**
 * TabBar component for managing tabs and navigation
 */
export function TabBar() {
  const { tabs, activeTabId, setActiveTabId, createNewTab, closeTab, updateTabIcon, updateTabTitle } = useTabs();
  const { settings } = useSettings();
  
  // Available tab icons
  const tabIcons = [
    'file-text', 'file-code', 'file-alt', 'sticky-note', 
    'book', 'bookmark', 'clipboard', 'edit', 'pen', 'pencil-alt'
  ];
  
  // Handle tab click
  const handleTabClick = (tabId) => {
    setActiveTabId(tabId);
  };
  
  // Handle tab rename
  const handleRenameTab = (tabId, newTitle) => {
    console.log('Renaming tab', tabId, 'to', newTitle);
    updateTabTitle(tabId, newTitle);
  };
  
  // Handle icon selection for a tab
  const handleIconSelect = (e, tabId) => {
    e.stopPropagation();
    const icon = prompt('Choose an icon:', tabIcons.join(', '));
    
    if (icon && tabIcons.includes(icon)) {
      updateTabIcon(tabId, icon);
    }
  };
  
  // Separate regular tabs and planner tab
  const regularTabs = tabs.filter(tab => !tab.isPlanner);
  const plannerTab = tabs.find(tab => tab.isPlanner);
  
  // Calculate if we should show the new tab button
  const showNewTabButton = regularTabs.length < 10;
  
  // Handle planner tab click as a toggle
  const handlePlannerToggle = () => {
    if (activeTabId === plannerTab?.id) {
      // If planner is active, switch to the first regular tab
      if (regularTabs.length > 0) {
        setActiveTabId(regularTabs[0].id);
      }
    } else {
      // If planner is not active, switch to it
      setActiveTabId(plannerTab?.id);
    }
  };
  
  return (
    <div className="tab-bar">
      <div className="tabs-container">
        {/* Regular content tabs */}
        {regularTabs.map((tab) => (
          <Tab
            key={tab.id}
            tab={tab}
            isActive={activeTabId === tab.id}
            onClick={() => handleTabClick(tab.id)}
            onClose={() => closeTab(tab.id)}
            onRename={(newTitle) => handleRenameTab(tab.id, newTitle)}
          />
        ))}
        
        {/* New Tab Button - now positioned immediately after regular tabs */}
        {showNewTabButton && (
          <button 
            className="new-tab-button"
            onClick={createNewTab}
            title="Create new tab"
            aria-label="Create new tab"
          >
            <i className="fas fa-plus"></i>
          </button>
        )}
      </div>
      
      {/* Right side container for Timer and Planner */}
      <div className="tab-bar-right">
        {/* Add PomodoroTimer component */}
        <PomodoroTimer />
        
        {/* Planner Tab - positioned at the right edge */}
        {plannerTab && (
          <div 
            className={`planner-tab ${activeTabId === plannerTab.id ? 'active' : ''}`}
            onClick={handlePlannerToggle}
            title="Toggle between notepad and planner views"
          >
            <i className="fas fa-calendar-alt mr-2"></i>
            <span className="tab-title">{plannerTab.title}</span>
          </div>
        )}
      </div>
    </div>
  );
}
