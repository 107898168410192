import { createContext } from 'preact';
import { useContext, useState, useEffect } from 'preact/hooks';
import { v4 as uuidv4 } from 'uuid';

// Create tabs context
const TabsContext = createContext();

/**
 * TabsProvider component for tab management
 */
export function TabsProvider({ children }) {
  // State for tabs and active tab
  const [tabs, setTabs] = useState([]);
  const [activeTabId, setActiveTabId] = useState(null);

  // Load tabs from localStorage on initial mount
  useEffect(() => {
    try {
      const savedTabs = JSON.parse(localStorage.getItem('tabs') || '[]');
      const savedActiveTabId = localStorage.getItem('activeTabId');
      
      if (savedTabs.length > 0) {
        setTabs(savedTabs);
        
        // Set active tab to saved active tab if it exists, otherwise use first tab
        if (savedActiveTabId && savedTabs.find(tab => tab.id === savedActiveTabId)) {
          setActiveTabId(savedActiveTabId);
        } else {
          setActiveTabId(savedTabs[0].id);
        }
      } else {
        // Create a default tab if none exist
        const defaultTab = createDefaultTab();
        setTabs([defaultTab]);
        setActiveTabId(defaultTab.id);
      }
    } catch (error) {
      console.error('Error loading tabs:', error);
      const defaultTab = createDefaultTab();
      setTabs([defaultTab]);
      setActiveTabId(defaultTab.id);
    }
  }, []);

  // Save tabs to localStorage whenever they change
  useEffect(() => {
    if (tabs.length > 0) {
      // Filter out the planner tab before saving to localStorage
      const tabsToSave = tabs.filter(tab => !tab.isPlanner);
      localStorage.setItem('tabs', JSON.stringify(tabsToSave));
      localStorage.setItem('activeTabId', activeTabId);
    }
  }, [tabs, activeTabId]);

  // Add the planner tab to the tabs array
  useEffect(() => {
    // Only add the planner if it's not already there
    if (!tabs.some(tab => tab.isPlanner)) {
      setTabs(prevTabs => {
        const plannerTab = createPlannerTab();
        return [...prevTabs, plannerTab];
      });
    }
  }, [tabs]);

  // Create a default tab
  function createDefaultTab(index = 1) {
    return {
      id: uuidv4(),
      title: `Tab ${index}`,
      content: '',
      icon: 'file-text',
      index: index,
      createdAt: Date.now(),
      modifiedAt: Date.now(),
      isPinned: false
    };
  }

  // Create the planner tab (pinned and always available)
  function createPlannerTab() {
    return {
      id: 'planner-tab', // Fixed ID for planner
      title: 'Planner',
      content: '', // Planner has static content
      icon: 'calendar-alt',
      index: 0, // Always first index
      createdAt: Date.now(),
      modifiedAt: Date.now(),
      isPinned: true,
      isPlanner: true // Special flag for the planner tab
    };
  }

  // Get the active tab
  const getActiveTab = () => {
    return tabs.find(tab => tab.id === activeTabId) || null;
  };

  // Create a new tab with sequential naming
  const createNewTab = () => {
    const newTabIndex = tabs.filter(tab => !tab.isPlanner).length + 1;
    const newTab = {
      id: uuidv4(),
      title: `Tab ${newTabIndex}`,
      content: '',
      icon: 'file-text',
      index: newTabIndex,
      createdAt: Date.now(),
      modifiedAt: Date.now(),
      isPinned: false
    };
    
    // Insert new tab before the planner tab (which should be last)
    const updatedTabs = [...tabs];
    const plannerTabIndex = updatedTabs.findIndex(tab => tab.isPlanner);
    
    if (plannerTabIndex >= 0) {
      updatedTabs.splice(plannerTabIndex, 0, newTab);
    } else {
      updatedTabs.push(newTab);
    }
    
    setTabs(updatedTabs);
    setActiveTabId(newTab.id);
    return newTab.id;
  };

  // Close a tab
  const closeTab = (tabId) => {
    // Don't allow closing the planner tab
    const tabToClose = tabs.find(tab => tab.id === tabId);
    if (tabToClose && tabToClose.isPlanner) {
      return;
    }
    
    // Remove the tab
    const updatedTabs = tabs.filter(tab => tab.id !== tabId);
    
    // If no tabs remain (except planner), create a new default tab
    if (updatedTabs.filter(tab => !tab.isPlanner).length === 0) {
      const defaultTab = createDefaultTab();
      updatedTabs.push(defaultTab);
      setActiveTabId(defaultTab.id);
    } 
    // If the closed tab was active, set another tab as active
    else if (activeTabId === tabId) {
      // Find the nearest non-planner tab
      const regularTabs = updatedTabs.filter(tab => !tab.isPlanner);
      if (regularTabs.length > 0) {
        setActiveTabId(regularTabs[0].id);
      }
    }
    
    setTabs(updatedTabs);
  };

  // Update tab content
  const updateTabContent = (tabId, content) => {
    setTabs(prevTabs => 
      prevTabs.map(tab => 
        tab.id === tabId ? { 
          ...tab, 
          content,
          modifiedAt: Date.now() 
        } : tab
      )
    );
  };

  // Update tab title
  const updateTabTitle = (tabId, title, autoTitle = false) => {
    // Log the update for debugging
    console.log('TabsContext: Updating tab title', { tabId, title, autoTitle });
    
    // Apply the update using a callback to ensure we're working with the most current state
    setTabs(prevTabs => {
      const updatedTabs = prevTabs.map(tab => 
        tab.id === tabId ? { 
          ...tab, 
          title, 
          autoTitle,
          modifiedAt: Date.now() 
        } : tab
      );
      
      // For debugging - show what changed
      console.log('TabsContext: Tab before/after update', {
        before: prevTabs.find(tab => tab.id === tabId),
        after: updatedTabs.find(tab => tab.id === tabId)
      });
      
      return updatedTabs;
    });
  };

  // Update tab icon
  const updateTabIcon = (tabId, icon) => {
    setTabs(prevTabs => 
      prevTabs.map(tab => 
        tab.id === tabId ? { 
          ...tab, 
          icon,
          modifiedAt: Date.now() 
        } : tab
      )
    );
  };

  // Export tab content
  const exportTab = (tabId, format = 'markdown') => {
    const tab = tabs.find(tab => tab.id === tabId);
    if (!tab) return null;
    
    const content = tab.content;
    const filename = `${tab.title.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.md`;
    
    // Create a download link
    const element = document.createElement('a');
    const file = new Blob([content], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    
    return {filename, content};
  };

  // Context value
  const value = {
    tabs,
    activeTabId,
    setActiveTabId,
    getActiveTab,
    createNewTab,
    closeTab,
    updateTabContent,
    updateTabTitle,
    updateTabIcon,
    exportTab
  };

  return (
    <TabsContext.Provider value={value}>
      {children}
    </TabsContext.Provider>
  );
}

/**
 * Hook to use the tabs context
 */
export function useTabs() {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error('useTabs must be used within a TabsProvider');
  }
  return context;
}
