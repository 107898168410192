import { createContext } from 'preact';
import { useContext, useState, useEffect } from 'preact/hooks';

// Create the theme context
const ThemeContext = createContext();

/**
 * Theme provider for managing dark/light mode
 */
export function ThemeProvider({ children }) {
  // State for theme (default to system preference, fallback to dark)
  const [theme, setTheme] = useState('dark');
  
  // Load theme from localStorage on initial mount
  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    
    // Use stored theme if available
    if (storedTheme) {
      setTheme(storedTheme);
    } else {
      // Otherwise check system preference
      const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      setTheme(prefersDark ? 'dark' : 'light');
    }
  }, []);
  
  // Update document and localStorage when theme changes
  useEffect(() => {
    // Set data-theme attribute on document element
    document.documentElement.setAttribute('data-theme', theme);
    
    // Update localStorage
    localStorage.setItem('theme', theme);
    
    // Also set a body class for backward compatibility
    if (theme === 'dark') {
      document.body.classList.add('dark-theme');
      document.body.classList.remove('light-theme');
    } else {
      document.body.classList.add('light-theme');
      document.body.classList.remove('dark-theme');
    }
  }, [theme]);
  
  // Toggle between dark and light mode
  const toggleTheme = () => {
    setTheme(prevTheme => prevTheme === 'dark' ? 'light' : 'dark');
  };
  
  // Direct theme setter
  const setThemeMode = (mode) => {
    if (mode === 'dark' || mode === 'light') {
      setTheme(mode);
    }
  };
  
  const value = {
    theme,
    isDarkMode: theme === 'dark',
    toggleTheme,
    setThemeMode
  };
  
  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  );
}

/**
 * Hook to use the theme context
 */
export function useTheme() {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}
